<template>
    <!-- 驰声录音 -->
    <div class="chi-recorder">
        <!-- {{playerUrl}} -->
        <audio controls controlsList="nodownload" class="audio-intitle" :src="playerUrl" v-if="playerUrl"></audio>
        <!-- <p>{{question}}</p> -->
        <div id="btn">
            <!-- <p style="height:50px">{{recorderStatus}}</p> -->
            <!-- <div style="margin-bottom:20px" v-if="playerStatus">
                <el-button @click="onplay()">播放录音</el-button>
            </div> -->
            <div>
                <el-button type="primary" @click="checkRecorder()" :loading="recorderLoading" style="margin-right:10px">
                    {{recorderLoading ? '录音中':'点击录音'}}
                </el-button>
                <el-button type="primary" @click="closeRecord()">停止录音</el-button>
            </div>
        </div>
        <div id="chivox-recorder"></div>
    </div>
</template>

<script>
    import Html5Recorder from 'chivox_h5sdk/src/html5/html5recorder'
    export default {
        props: {
            // id
            question: {
                type: String,
                default: ''
            },
            questId: {
                type: Number,
            }
        },
        data() {
            return {
                player: null,
                recorder: null,
                recorderFlag: false, //是否在录音
                playerStatus: false,
                playerUrl: undefined,
                recorderLoading: false, //录音状态
                recorderStatus: undefined,

            }
        },
        methods: {
            // 回显答案
            previewAnswer() {
                let answercache = JSON.parse(localStorage.getItem("activeAnswer"));
                try {
                    let index = answercache.findIndex(item => {
                        return item.questId == this.questId
                    })
                    if (index < 0) {
                        // 没有答过不用回显
                    } else {
                        // 答过需回显答案
                        // console.log(JSON.parse(answercache[index].answer))
                        // this.currentAnswer = this.answercache[index].answer
                        this.playerUrl =
                            `http://download.cloud.chivox.com/${JSON.parse(answercache[index].answer).uuid}.mp3`
                        // this.recorderStatus = '录音成功';
                    }
                } catch (err) {
                    console.log(err)
                    // this.recorderStatus = '录音失败';
                }
            },
            // 获取驰声初始化参数
            getInitParam() {
                return this.$Api.Activity.getInitParam().then(res => {
                    // console.log('驰声初始化数据', res);
                    this.initChiShengSdk(res.data);
                    // return Promise.resolve(res.data)
                })
            },
            // 初始化sdk
            initChiShengSdk(initParam) {
                console.log('initParam', initParam)
                this.player = new Html5Player();
                this.recorder = new Html5Recorder({
                    appKey: initParam.appKey, //驰声授权的appKey
                    sigurl: initParam.sigUrl, //获取验证身份信息的接口地址
                    server: initParam.server,
                    onInit: function (mess) {
                        //引擎初始化成功
                        console.log("Init success!")

                    },
                    onError: function (err) {
                        //引擎运行错误
                        alert('初始化错误，请联系管理员')
                        console.log("Sdk onError:" + err)
                    }
                })
            },
            checkRecorder() {
                this.recorderFlag = true;
                if (this.playerUrl) {
                    this.$confirm('重新录音将覆盖上一次的内容?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.chishengRecord();
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '取消录音'
                        });
                        this.recorderLoading = false;
                        return;
                    });
                } else {
                    this.chishengRecord();
                }
            },
            // 开始录音
            chishengRecord() {
                let that = this;
                this.recorder.record({
                    duration: 1000 * 60,
                    playDing: true,
                    serverParams: {
                        coreType: "cn.pred.raw",
                        // res: "chn.snt.G4.custom",
                        refText: that.question,
                        attachAudioUrl: 1
                    },
                    onStart: function () {
                        console.log("onStart");
                        that.recorderLoading = true;
                    },
                    onStop: function () {
                        console.log("onStop");
                        that.recorderLoading = false;
                    },
                    onScore: function (score) {
                        /*评测成功允许回放按钮样式*/
                        // this.recorder.stopRecord();
                        that.recorderFlag = false;
                        that.playerStatus = true;
                        that.playerUrl = `http://download.cloud.chivox.com/${score.uuid}.mp3`
                        that.recorderStatus = '录音成功';
                        that.$message.success('录音成功');
                        console.log('onScore', score);
                        that.$emit('chiResult', score)


                    },
                    onScoreError: function (err) {
                        that.recorderStatus = '录音失败';
                        that.$message.error('录音错误', err);
                        console.log("onScoreError", err);
                    }
                })
            },
            closeRecord() {
                this.recorderFlag = false;
                this.recorder.stopRecord();
                // this.recorder.showVolumeBar((data) => {
                //     // console.log(data)
                // })
            },
            // onplay() {
            //     let that = this;
            //     this.player.setVolume(0.9); //设置播放音频音量大小 0~1 
            //     this.player.load({
            //         url: `${that.playerUrl}.mp3`,
            //         success: function () {
            //             console.log("player start success.");
            //             player.play({
            //                 position: 0, //播放位置
            //                 duration: 8000, //播放时长
            //                 onStop: function () {
            //                     console.log("player onStop");
            //                     console.log("Volume:" + player
            //                         .getVolume()); //获取当前播放音频的音量，精度与设置的有出入
            //                 },
            //                 onStart: function () {
            //                     console.log("player onStart");
            //                 },
            //                 error: function (err) {
            //                     console.log(err);
            //                 }
            //             })
            //         },
            //         error: function (err) {
            //             player.stop(); //手动停止音频播放
            //             player.reset(); //重置播放器状态
            //             console.log("player error:" + JSON.stringify(err));
            //         }
            //     })
            // },
        },
        mounted() {
            // console.log('chishengMounted')
            // console.log(this.question)
            this.getInitParam();
            this.previewAnswer();
        },

        destroyed() {
            // console.log('chishengDestroyed')
        }

    }
</script>

<style>

</style>