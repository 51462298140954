<template>
    <!-- 说话题 -->
    <div>
        <audio controls controlsList="nodownload" class="audio-intitle" :src="recorderURL" v-if="recorderURL"></audio>
        <br>
        <el-button type="primary" @click="startRecorder" :loading="recorderLoading" style="margin-right:10px">
            {{recorderLoading ? '录音中':'点击录音'}}
        </el-button>
        <el-button type="primary" @click="stopRecorder">停止录音</el-button>
    </div>
</template>

<script>
    import Recorder from 'js-audio-recorder'
    import {
        uploadOSSFile
    } from './uoload-audio'
    export default {
        props: {
            // id
            questId: {
                type: Number,
                default: undefined
            },
            pleaseStop: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                recorder: undefined, //录音
                recorderURL: undefined,
                recorderLoading: false,
            }
        },
        watch: {
            'pleaseStop': {
                handler(newVal, oldVal) {
                    if (newVal) {
                        this.stopRecorder();
                    }
                },
            }
        },
        methods: {
            // 回显答案
            previewAnswer() {
                let answercache = JSON.parse(localStorage.getItem("activeAnswer"));
                try {
                    let index = answercache.findIndex(item => {
                        return item.questId == this.questId
                    })
                    if (index < 0) {
                        // 没有答过不用回显
                    } else {
                        // 答过需回显答案
                        this.recorderURL = this.downloadURL + answercache[index].answer
                    }
                } catch (err) {
                    console.log(err)
                }
            },
            // 开始录音
            startRecorder() {
                // 未录音
                if (!this.recorderURL) {
                    this.trueStartRecorder();
                }
                // 已存在录音
                if (this.recorderURL) {
                    this.$confirm('重新录音将覆盖上一次的内容?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.trueStartRecorder();
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '取消录音'
                        });
                        this.recorderLoading = false;
                        return;
                    });
                }
            },
            // 录音方法
            trueStartRecorder() {
                this.recorder = new Recorder({
                    sampleBits: 16,
                    sampleRate: 16000,
                    numChannels: 1
                })

                this.recorder.start().then(() => {
                    console.log('开始录音')
                    this.recorderLoading = true;
                }, (error) => {
                    console.log('出错了', error)
                    this.recorderLoading = false;
                    this.$message.error('录音错误', error);
                })
            },
            //停止录音
            stopRecorder() {
                this.recorder.stop();
                this.$message.success('录音成功');
                this.recorderLoading = false;
                let wavBlob = this.recorder.getWAVBlob();
                // console.log('wavBlob', wavBlob);
                let file = new File([wavBlob], `audio-${new Date().getTime()}.wav`, {
                    type: 'audio/wav'
                })
                this.recorderURL = URL.createObjectURL(wavBlob);
                // console.log(file)
                uploadOSSFile(file).then((res) => {
                    console.log('回调', res)
                    // this.currentAnswer = res.identifier
                    // this.answer();
                    this.$emit('speakAnswer', res.identifier);
                });
            },
        },
        mounted() {
            // console.log('speakMounted')
            this.previewAnswer();
        },

    }
</script>

<style>

</style>