import SparkMD5 from "spark-md5";
let OSS = require('ali-oss')

let options = {
    chunkNumber: null, //当前文件块，从1开始
    chunkSize: 10 * 1024 * 1024, //分块大小
    currentChunkSize: null, //当前分块大小
    totalSize: null, //总大小
    identifier: null, //文件标识
    currentIdentifier: null, //当前文件标识
    filename: null, //文件名
    relativePath: null, //文件路径（阿里云路径）
    totalChunks: null, //总块数
    type: null, //文件类型
    chunkData: null //文件数据
}
let client = {}

// 请求oss令牌
async function getQueryToken() {
    let resData = await VueInstance.$Api.OssUpload.getQueryToken()
    client = new OSS({
        region: 'oss-cn-beijing', //根据那你的Bucket地点来填写
        accessKeyId: resData.data.accessKeyId, //自己账户的accessKeyId
        accessKeySecret: resData.data.accessKeySecret, //自己账户的accessKeySecret
        bucket: resData.data.bucket, //bucket名字
        stsToken: resData.data.stsToken
    });
    return new Promise((resolve, reject) => {
        resolve(resData)
    })
}
// 上传至服务器
function submitForm(file) {
    if (file.size > 10485760) {
        console.log('>10M')
        this.multipartUpload();
    } else(

        setTimeout(() => {
            console.log("<10M")
            handleHttpRequest(file)
        }, 1000)
    )
}
// 合并提交
async function postMerge(options, file, filePath) {
    console.log('_+++++', VueInstance.$store.state.user.currentProject.id)
    let data = {
        filename: options.filename, //文件名
        identifier: options.identifier, //文件标识
        totalSize: options.totalSize, //总大小
        type: file.type, //请求类型
        location: filePath, //文件路径（阿里云路径
        ext: options.type, //文件类型
        projectId: VueInstance.$store.state.user.currentProject.id,
        // sourceType: this.sourceType
    }
    let resData = await VueInstance.$Api.OssUpload.postMerge(data)
    console.log("resData", resData);
    // this._fileComplete()
    // this.Completeinfo(data)
    // if (resData) {

    // }
}
// 
async function handleHttpRequest(file) { //上传OSS
    try {
        // 简单上传文件
        let filePath = 'upload/' + VueInstance.dayjs(parseInt(new Date().getTime())).format('YYYY/MM/DD') +
            '/' + options.identifier + '/' + options.filename;
        //向OSS上传文件
        const result = await client.put(filePath, file)
        console.log('123', result)
        //向服务器登记
        postMerge(options, file, filePath)
    } catch (e) {
        console.error(e);
        if (e.code === 'ConnectionTimeoutError') { // 请求超时异常处理
            console.log("TimeoutError");
            // do ConnectionTimeoutError operation
        }
    }

}
// 上传前检查
async function getCheck(res, file) {
    console.log('file--', file)
    let data = {
        identifier: res,
        projectId: VueInstance.$store.state.user.currentProject.id,
        //FIXME  sourceType: this.sourceType,
        filename: file.name
    }
    let resData = await VueInstance.$Api.OssUpload.getCheck(data)
    // console.log(resData)
    if (!resData.data.check && !resData.data.fileInfo && !resData.data.chunkInfo) {
        // console.log(file)
        // submitForm(file)
        handleHttpRequest(file)
    }
    if (resData.data.check && resData.data.chunkInfo) {
        tempCheckpoint = JSON.parse(resData.data.chunkInfo.chunkData)
        resumeUpload()
    }
    if (resData.data.check && resData.data.fileInfo) {
        _fileComplete()
        Completeinfo(resData.data.fileInfo)
    }

}

// 计算MD5
function computeMD5(file) {
    let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
    let chunkSize = 10 * 1024 * 1024
    let chunks = Math.ceil(file.size / chunkSize)
    let currentChunk = 0
    let spark = new SparkMD5.ArrayBuffer()
    let fileReader = new FileReader()

    return new Promise(function (resolve, reject) {
        fileReader.onload = function (e) {
            spark.append(e.target.result)
            currentChunk++
            if (currentChunk < chunks) {
                loadNext()
                console.log(
                    `第${currentChunk}分片解析完成, 开始第${currentChunk +
                  1} / ${chunks}分片解析`
                );
            } else {
                let md5 = spark.end(); //得到md5
                function getCaption(obj) {
                    var index = obj.lastIndexOf(".");
                    obj = obj.substring(index + 1, obj.length);
                    return obj;
                }
                let filemd5 = md5 + "_" + file.size + "_" + getCaption(file.name);

                options.currentIdentifier = md5;
                options.identifier = filemd5;
                options.totalSize = file.size;
                options.filename = file.name;
                options.totalChunks = currentChunk;
                options.type = getCaption(file.name);
                getCheck(filemd5, file)
                console.log('computed hash', filemd5)
            }

            resolve(options);
        }
        
        fileReader.onerror = function () {
            console.warn('FileReader error.')
        }

        function loadNext() {
            let start = currentChunk * chunkSize
            let end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize
            // 注意这里的 fileRaw
            fileReader.readAsArrayBuffer(blobSlice.call(file, start, end))
        }
        loadNext()
    })

}

//将文件上传到OSS
export function uploadOSSFile(file) {
    //获取oss数据
    let queryToken = getQueryToken();

    return new Promise((resolve, reject) => {

        Promise.all([queryToken]).then((values) => {
            if (!values) return;
            console.log("client", client);
            //计算MD5
            computeMD5(file).then((res) => {
                resolve(options)
            });
            // console.log("options", options);
        });
    })


}