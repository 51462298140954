<!-- 正式比赛 -->
<template>
    <div class="matchWrap" v-wechat-title="`${$route.meta.title}_双优云桥-智慧研修平台`">
        <div class="match">
            <div class="match_l">
                <div class="plan flex-column">
                    <div class="plan_top flex-align-between">
                        <span>答题进度</span>
                        <span>{{isAnswerNum}}/{{questionList.length}}</span>
                    </div>
                    <el-slider v-model="value" :show-tooltip='tooltip' :min='1' :max="maxval" disabled></el-slider>
                    <span>共{{questionList.length}}题</span>
                </div>
                <div class="number_t">
                    <div class="item" :class="{'select_item':currentIndex==index,'isanswer':item.isAnswer}"
                        v-for="(item,index) in questionList" :key="index" @click="seleceNum(index)">{{index+1}}</div>
                    <div class="hideitem" v-for="(item,index) in 3" :key="index+55"></div>
                </div>
                <div class="line"></div>
                <!-- <div class="number_b">
                    <div class="item" :class="{'select_item':currentIndex==item}" v-for="(item,index) in 10"
                        :key="index" @click="seleceNum(item)">{{item}}</div>
                    <div class="hideitem" v-for="(item,index) in 3" :key="index+55"></div>
                </div> -->
            </div>
            <div class="match_r">
                <div class="title flex-align-between">
                    <span>{{$route.query.type==1?'正式比赛':'模拟练习'}}</span>
                    <div class="time flex-align">
                        <img src="../../assets/images/active/time.png" />
                        <span>剩余时间</span>
                        <span>{{countdown}}</span>
                    </div>
                </div>
                <div class="content">
                    <div class="questype">
                        <!-- <span>{{currentQuestion.type==1?'单选题':currentQuestion.type==2?'多选题':currentQuestion.type==3?'判断题':currentQuestion.type==4?'填空题':'附件题'}}</span> -->
                        <span>{{typeMap[currentQuestion.type]}}</span>
                    </div>
                    <!-- <span>{{currentIndex+1}}.{{currentQuestion.title}}</span> -->
                    <div>
                        <span>{{currentIndex+1}}.</span>
                        <div v-html="currentQuestion.title" class="qtitle"></div>
                    </div>
                    <div class="line"></div>
                    <div class="options flex-column">
                        <!-- 单选题、判断题 -->
                        <template v-if="currentQuestion.type==1||currentQuestion.type==3">
                            <div class="optionItem" v-for="(item,index) in currentOptions" :key="index"
                                @click="select('radio',index)">
                                <div class="serial" :class="{'select_serial':defaultOption==index}">{{item.value}}</div>
                                <div class="optionContent" :class="{'select_optionContent':defaultOption==index}"
                                    v-html="item.label">
                                    <!-- {{item.label}} -->
                                </div>
                            </div>
                        </template>
                        <!-- 多选题 -->
                        <template v-if="currentQuestion.type==2">
                            <div class="optionItem flex-align" v-for="(item,index) in currentOptions" :key="index"
                                @click="select('chexkbox',index)">
                                <div class="serial" :class="{'select_serial':item.isSelect==true}">{{item.value}}</div>
                                <div class="optionContent" :class="{'select_optionContent':item.isSelect==true}"
                                    v-html="item.label">
                                    <!-- {{item.label}} -->
                                </div>
                            </div>
                        </template>
                        <!-- 单字题 -->
                        <template v-if="currentQuestion.type==5 || currentQuestion.type==6 ||currentQuestion.type==7">
                            <div class="uploadWrap" :key="currentQuestion.id">
                                <chisheng @chiResult="getChiVoxResult" :question="currentTitle"
                                    :questId="currentQuestion.id"></chisheng>
                            </div>
                        </template>
                        <template v-if="currentQuestion.type==8">
                            <div class="uploadWrap">
                                <!-- 图片区域 -->
                                <template>
                                    <div class="imgwrap" v-for="(item,index) in imglist" :key="index">
                                        <div class="mask" @click="deleteImg(item)">
                                            <i class="el-icon-delete" style="color: #ffffff; font-size: 16px"></i>
                                        </div>
                                        <el-image style="width: 100%; height: 100%" fit="fit" :src="imgurl+item"
                                            :preview-src-list="previewlist"></el-image>
                                    </div>

                                </template>
                                <!-- 视频区域 -->
                                <template>
                                    <div class="videowrap" v-for="(item) in videolist" :key="item.id">
                                        <div class="mask" @click="deleteVideo(item)">
                                            <i class="el-icon-delete" style="color: #ffffff; font-size: 16px"></i>
                                        </div>
                                        <video controls="controls" width="200px" height="150" :src="imgurl+item">
                                            您的浏览器不支持播放该视频！
                                        </video>
                                    </div>

                                </template>
                                <!-- 不支持的格式区域 -->
                                <template>
                                    <el-upload class="upload-demo" action="/" :file-list="filelist" disabled>
                                    </el-upload>
                                </template>
                            </div>
                            <div :key="currentQuestion.id">
                                <speak @speakAnswer="getSpeakAnswer" :questId="currentQuestion.id"
                                    :pleaseStop="pleaseStop"></speak>
                            </div>
                        </template>
                        <!-- 附件题 -->
                        <template v-if="currentQuestion.type==9">
                            <div class="uploadWrap">
                                <!-- 图片区域 -->
                                <template>
                                    <div class="imgwrap" v-for="(item,index) in imglist" :key="index">
                                        <div class="mask" @click="deleteImg(item)">
                                            <i class="el-icon-delete" style="color: #ffffff; font-size: 16px"></i>
                                        </div>
                                        <el-image style="width: 100%; height: 100%" fit="fit" :src="imgurl+item"
                                            :preview-src-list="previewlist"></el-image>
                                    </div>

                                </template>
                                <!-- 视频区域 -->
                                <template>
                                    <div class="videowrap" v-for="(item) in videolist" :key="item.id">
                                        <div class="mask" @click="deleteVideo(item)">
                                            <i class="el-icon-delete" style="color: #ffffff; font-size: 16px"></i>
                                        </div>
                                        <video controls="controls" width="200px" height="150" :src="imgurl+item">
                                            您的浏览器不支持播放该视频！
                                        </video>
                                    </div>

                                </template>
                                <!-- 不支持的格式区域 -->
                                <template>
                                    <el-upload class="upload-demo" action="/" :file-list="filelist" disabled>
                                    </el-upload>
                                </template>
                            </div>
                            <el-button type="primary" @click="showUpload">上传附件</el-button>
                        </template>
                        <!-- 上传区域 -->
                        <!-- 本地断点续传 -->
                        <el-drawer v-if="!BreakPointUpload" title="" @opened="openedDrawer"
                            :visible.sync="UploadPicture" :before-close="handleClose" @close='closeDrawer'>
                            <upimg v-if="UploadPicture" ref="upimg" :upimgload="true" @closeMain="closeMain"></upimg>
                        </el-drawer>
                        <!-- 阿里云上传 -->
                        <el-drawer v-if="BreakPointUpload" title="" :visible.sync="UploadPicture"
                            :before-close="handleClose" @close='closeDrawer'>
                            <ossUploader v-if="UploadPicture" @closeMain="closeMain"></ossUploader>
                        </el-drawer>
                    </div>
                </div>
                <div class="btn">
                    <div>
                        <div class="prev" :class="{'noprev':currentIndex==0}" @click="prev">上一题</div>
                        <div class="next" @click="next">{{nextText}}</div>
                    </div>
                </div>
            </div>
            <!-- 提交弹窗 -->
            <el-dialog :visible="dialogVisible" :show-close="showClose">
                <img class="subtip" src="../../assets/images/active/feiji.png" />
                <span class="tip">{{subtips}}</span>
                <div class="btn">
                    <div class="error" @click="close">点错了</div>
                    <div class="ready" @click="confirm">确定</div>
                </div>
            </el-dialog>
            <!-- 正式比赛完成弹窗 -->
            <el-dialog class="finishpop" :visible="finishVisible" :show-close="showClose">
                <img class="medal" src="../../assets/images/active/medal.png" />
                <img class="close" src="../../assets/images/active/close.png" @click="closeDialog" />
                <span class="tips">恭喜您完成比赛，敬请期待公布成绩吧！</span>
            </el-dialog>
            <!-- 答题时间到弹窗 -->
            <el-dialog class="timepop" :visible="timeVisible" :show-close="showClose">
                <img class="timeend" src="../../assets/images/active/timeend.png" />
                <span class="tip">考试时间到，本次作答结束！</span>
                <div class="endConfirm" @click="endConfirm">确定</div>
            </el-dialog>
            <!-- 漏题弹窗 -->
            <el-dialog class="misspop" :visible="missVisible" :show-close="showClose">
                <img class="bell" src="../../assets/images/active/bell.png" />
                <span class="tip">您还有习题没有作答，确认要{{exittype=='submit'?'提交':'退出'}}吗？</span>
                <div class="btn">
                    <div class="error" @click="close">点错了</div>
                    <div class="ready" @click="missConfirm">确定</div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>
    import Html5Recorder from 'chivox_h5sdk/src/html5/html5recorder'
    let OSS = require('ali-oss')
    import questArr from './question.json'
    import Recorder from 'js-audio-recorder'
    import {
        uploadOSSFile
    } from './components/uoload-audio'
    import chisheng from './components/chisheng.vue'
    import speak from './components/speak.vue'

    export default {
        data() {
            return {
                maxval: 100,
                tooltip: false,
                value: 1,
                countdown: 0,
                dialogVisible: false,
                finishVisible: false,
                timeVisible: false,
                missVisible: false,
                showClose: false,
                defaultOption: -1,
                questionList: [],
                currentQuestion: {},
                currentTitle: '',
                currentOptions: [],
                currentIndex: 0,
                nextText: '下一题',
                isAnswerNum: 0, //已答题数量
                historyId: null, //答题记录id
                questionnaireId: null, //问卷id(生成的问卷id)
                currentAnswer: '', //当前问题的答案
                UploadPicture: false,
                videoTypes: ["mp4", "3gp", "m4v", "mkv", ],
                picTypes: ["png", "jpg", "gif", "jpeg"],
                uploadData: [], //上传的数据
                previewlist: [],
                imglist: [],
                videolist: [],
                filelist: [],
                imgurl: this.downloadURL,
                intervalId: null,
                localcache: [],
                answercache: [], //答案本地缓存(临时存储，仅用于上下题切换显示答案)
                popStatus: 0,
                exit: false,
                path: '',
                exittype: null,
                isAnswerList: [],
                subtips: '',
                isEmpty: false,
                qtitle: '',
                // recorder: undefined, //录音
                // recorderURL: undefined,
                // recorderLoading: false,
                pleaseStop: false,
                typeMap: {
                    1: '单选题',
                    2: '多选题',
                    3: '判断题',
                    4: '填空题',
                    5: '单字题',
                    6: '多字题',
                    7: '朗读题',
                    8: '说话题',
                    9: '附件题',
                }
            }
        },
        components: {
            chisheng,
            speak
        },
        methods: {
            getSpeakAnswer(data) {
                this.pleaseStop = false;
                this.currentAnswer = data
                this.answer();
            },
            getChiVoxResult(data) {
                console.log('驰声成绩结果', data);
                this.currentAnswer = JSON.stringify(data)
                this.answer();
            },
            closeDialog() {
                this.finishVisible = false;
                this.$router.go(-1);
            },
            openedDrawer() {
                this.$refs.upimg.$el.querySelector("input").click();
            },
            // 删除图片
            deleteImg(item) {
                console.log('删除图片', item);
                let index = this.imglist.indexOf(item);
                this.imglist.splice(index, 1);
                let arr = this.currentAnswer.split(',')
                let cindex = arr.indexOf(item);
                arr.splice(cindex, 1);
                this.currentAnswer = arr.join(',');
                console.log('删除后的当前答案', this.currentAnswer)

            },
            // 删除视频
            deleteVideo(item) {
                console.log('删除视频', item);
                let index = this.videolist.indexOf(item);
                this.videolist.splice(index, 1);
                let arr = this.currentAnswer.split(',')
                let cindex = arr.indexOf(item);
                arr.splice(cindex, 1);
                this.currentAnswer = arr.join(',');
                console.log('删除后的当前答案', this.currentAnswer)
            },
            close() {
                this.dialogVisible = false;
                this.missVisible = false;
            },
            // 确认交卷
            confirm() {
                this.submit()
                    .then(res => {
                        console.log('promise链式调用', res);
                        console.log('显示正式比赛完成弹窗');
                        this.dialogVisible = false;
                        this.exit = true;
                        if (this.$route.query.type == 0) {
                            this.$router.push({
                                path: "/testResult",
                                query: {
                                    historyId: this.historyId,
                                    breadNumber: 5
                                }
                            })
                        } else {
                            this.finishVisible = true;
                        }
                    })
            },
            // 答题时间到确认按钮
            endConfirm() {
                // 答题时间到，自动提交，不判断是否有漏题
                this.exit = true
                this.timeVisible = false;
                if (this.$route.query.type == 0) {
                    console.log('模拟练习，跳转模拟结果页面')
                    this.$router.push({
                        path: "/testResult",
                        query: {
                            historyId: this.historyId,
                            breadNumber: 5,
                        }
                    })
                } else {
                    console.log('正式比赛,跳转活动详情页')
                    this.$router.push({
                        path: `/newActiveDetail/${this.$route.query.activeId}?breadNumber=4`
                    })
                }
            },
            // 漏题确认
            missConfirm() {
                this.exit = true;
                if (this.exittype == 'submit') {
                    this.confirm()
                } else {
                    this.$router.push({
                        path: this.path,
                        query: {
                            breadNumber: 4,
                        }
                    })
                }

            },
            // 选择选项
            select(type, index) {
                if (type == 'radio') {
                    // 单选
                    this.defaultOption = index;
                    this.currentAnswer = this.currentOptions[index].value
                } else {
                    // 多选
                    let obj = {
                        label: this.currentOptions[index].label,
                        value: this.currentOptions[index].value,
                        isSelect: !this.currentOptions[index].isSelect
                    }
                    this.$set(this.currentOptions, index, obj);
                    if (obj.isSelect) {
                        this.currentAnswer += obj.value;
                    } else {
                        this.currentAnswer = this.currentAnswer.replace(obj.value, '');
                    }
                }
            },
            // 展示录音按钮
            showRecorderBtn() {
                console.log('当前题目', this.currentQuestion);
                return false;
            },
            // 选择题号
            seleceNum(index) {
                if (this.currentIndex == index) {
                    console.log('选择的题号不变')
                } else {
                    this.currentIndex = index;
                    this.toggle()
                }

            },
            // 切换题目
            toggle() {
                this.answer()
                    .then(res => {
                        this.currentQuestion = this.questionList[this.currentIndex];
                        this.currentTitle = this.currentQuestion.title;
                        if (this.currentQuestion.type == 5 && this.currentQuestion.title.indexOf('[VOICE:]') != -
                            1) {
                            this.currentQuestion.isRecorder = true;
                            this.currentQuestion.title = this.currentQuestion.title.replace('[VOICE:]', '')
                        }
                        this.value = this.currentIndex + 1;
                        this.currentAnswer = ''; //清空当前答案
                        this.imglist = [];
                        this.previewlist = [];
                        this.videolist = [];
                        this.filelist = [];
                        if (this.currentQuestion.type == 1 || this.currentQuestion.type == 3) {
                            // this.currentOptions = JSON.parse(this.currentQuestion.answerOptions);
                            this.currentOptions = this.currentQuestion.answerOptions;
                            this.defaultOption = -1;
                            this.previewAnswer();
                            this.defaultOption = this.currentOptions.findIndex(item => {
                                return item.value == this.currentAnswer;
                            })
                        } else if (this.currentQuestion.type == 2) {
                            // this.currentOptions = JSON.parse(this.currentQuestion.answerOptions)
                            this.currentOptions = this.currentQuestion.answerOptions
                            this.currentOptions.map(item => {
                                item.isSelect = false
                            });
                            this.previewAnswer();
                            let answer = this.currentAnswer.split(',');
                            for (var i = 0; i < answer.length; i++) {
                                for (var j = 0; j < this.currentOptions.length; j++) {
                                    if (answer[i] == this.currentOptions[j].value) {
                                        this.currentOptions[j].isSelect = true;
                                        break
                                    }
                                }
                            }
                        } else {
                            // 当前题型是主观题没有currentOptions选项
                            this.defaultOption = -1;
                            this.previewAnswer();
                            if (this.currentAnswer) {
                                let answer = this.currentAnswer.split(',');
                                console.log('answer--', answer)
                                answer.pop();
                                answer.map(item => {
                                    if (this.picTypes.indexOf(item.split('_')[2].toLowerCase()) >= 0) {
                                        console.log('图片item', item)
                                        this.imglist.push(item);
                                        this.previewlist.push(item);
                                    } else if (this.videoTypes.indexOf(item.split('_')[2].toLowerCase() >=
                                            0)) {
                                        console.log('视频item', item)
                                        this.videolist.push(item);
                                    } else {
                                        this.filelist.push(item)
                                    }
                                })
                            }
                        }
                        if (this.currentIndex == this.questionList.length - 1) {
                            this.nextText = "我要交卷"
                        } else {
                            this.nextText = "下一题"
                        }
                    })
                    .catch(err => {
                        console.log('回答失败')
                    })

            },
            // 回答问题
            answer() {
                if (!this.currentAnswer) {
                    console.log('没选答案，不走答题接口');
                    return Promise.resolve('没选答案，不走答题接口')
                } else {
                    let data = {
                        historyId: this.historyId,
                        questId: this.currentQuestion.id,
                        questionnaireId: this.questionnaireId,
                        type: this.currentQuestion.type,
                        answer: this.currentQuestion.type == 2 ? this.currentAnswer.split('').join(',') : this
                            .currentAnswer,
                    }
                    return this.$Api.Activity.answer(data)
                        .then(res => {
                            // console.log('回答成功', res);
                            let answer = {
                                questId: data.questId,
                                answer: data.answer
                            };
                            // console.log('answer', answer)
                            // 1.获取本地答案缓存
                            let localanswer = JSON.parse(localStorage.getItem("activeAnswer"));
                            // console.log('localanswer', localanswer)
                            // 2.判断本地缓存中是否存储过该问题的答案
                            if (!localanswer) {
                                this.localcache.push(answer);
                                localStorage.setItem("activeAnswer", JSON.stringify(this.localcache))
                            } else {
                                let index = localanswer.findIndex(item => {
                                    return item.questId == answer.questId
                                })
                                if (index < 0) {
                                    // 没存过
                                    localanswer.push(answer);
                                    localStorage.setItem("activeAnswer", JSON.stringify(localanswer))
                                } else {
                                    // 存过
                                    localanswer.splice(index, 1, answer);
                                    console.log('替换过的答案', localanswer);
                                    localStorage.setItem("activeAnswer", JSON.stringify(localanswer))
                                }
                            }
                            //  将题目状态变成已答题状态
                            for (var i = 0; i < this.questionList.length; i++) {
                                if (this.questionList[i].id == data.questId) {
                                    this.questionList[i].isAnswer = true
                                    break
                                }
                            }
                            console.log('data', data)
                            return Promise.resolve("回答成功");
                        })
                        .catch(err => {
                            console.log('回答失败', err)
                        })
                }

            },
            // 回显答案
            previewAnswer() {
                this.answercache = JSON.parse(localStorage.getItem("activeAnswer"));
                try {
                    let index = this.answercache.findIndex(item => {
                        return item.questId == this.currentQuestion.id
                    })
                    if (index < 0) {
                        // 没有答过不用回显
                    } else {
                        // 答过需回显答案
                        this.currentAnswer = this.answercache[index].answer
                    }
                } catch (err) {
                    console.log(err)
                }

            },
            // 开始答题获取题目
            getQuestion() {
                let data = {
                    stageId: this.$route.query.stageId,
                    type: this.$route.query.type
                }
                this.$Api.Activity.getQuest(data)
                    .then(res => {
                        console.log('获取成功', res);
                        res.data.quests.map(item => {
                            item.isAnswer = false
                        });
                        this.isAnswerList = res.data.answers; //已答题列表
                        // this.questionList = res.data.quests; //题目列表
                        // this.currentQuestion = res.data.quests[0];//当前题目
                        // this.currentOptions = JSON.parse(res.data.quests[0].answerOptions);//当前选项

                        res.data.quests.map(item => {
                            item.title = this.test(item.title);
                            if (!item.answerOptions) {
                                console.log('附件题')
                            } else {
                                item.answerOptions = JSON.parse(item.answerOptions);
                                item.answerOptions.map(items => {
                                    items.label = this.test(items.label)
                                })
                            }
                        })
                        // console.log('questionList', res.data.quests)
                        this.questionList = res.data.quests
                        this.currentQuestion = this.questionList[0];
                        this.currentOptions = this.questionList[0].answerOptions

                        this.maxval = res.data.quests.length;
                        this.historyId = res.data.historyId;
                        this.questionnaireId = res.data.id;
                        this.timer(res.data.matchDuration * 1000 - (Date.now() - new Date(res.data
                            .historyCreateTime).getTime()));
                        // 清除答案缓存
                        localStorage.removeItem("activeAnswer");
                        // 回显已答题
                        if (res.data.answers.length > 0) {
                            for (var i = 0; i < res.data.answers.length; i++) {
                                for (var j = 0; j < res.data.quests.length; j++) {
                                    if (res.data.answers[i].questId == res.data.quests[j].id) {
                                        res.data.quests[j].isAnswer = true;
                                        break
                                    }
                                }
                            }
                            // 回显第一题答案
                            let index = this.isAnswerList.findIndex(item => item.questId == this.currentQuestion
                                .id);
                            console.log('index', index)
                            let oindex = JSON.parse(this.currentQuestion.answerOptions).findIndex(item => item
                                .value == this.isAnswerList[index].answer)
                            this.defaultOption = oindex
                            localStorage.setItem("activeAnswer", JSON.stringify(res.data.answers))
                        }


                    })
                    .catch(err => {
                        // console.log('获取失败', err);
                    })
            },
            // 时间转换
            timeFormat(time) {
                var d = parseInt(time / (1000 * 60 * 60 * 24));
                var h = parseInt((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var m = parseInt((time % (1000 * 60 * 60)) / (1000 * 60));
                var s = parseInt((time % (1000 * 60)) / 1000);
                if (h < 10) {
                    h = '0' + h
                }
                this.countdown = d ? d + ':' : '' + h + ':' + (m < 10 ? '0' + m : m) + ':' + (s < 10 ? '0' + s : s)
            },
            // 倒计时
            timer(time) {
                this.intervalId = setInterval(() => {
                    time -= 1000;
                    this.timeFormat(time);
                    if (time <= 0) {
                        clearInterval(this.intervalId);
                        this.countdown = 0;
                        // 时间到自动提交
                        this.submit()
                            .then(res => {
                                // 显示考试时间到弹窗
                                this.timeVisible = true
                            })
                            .catch(err => {
                                console.log('提交失败', err)
                            })
                    }
                }, 1000);
            },
            prev() {
                if (this.currentIndex == 0) {
                    this.$message({
                        message: '当前已经是第一题了哦~',
                        type: 'warning'
                    });
                } else {
                    this.currentIndex -= 1;
                    this.toggle()
                }
            },
            next() {
                if (this.nextText == "我要交卷") {
                    if (this.currentQuestion.type == 8) {
                        this.pleaseStop = true;
                    }
                    this.answer()
                        .then(res => {
                            this.missAnswer();
                            console.log('我要交卷')
                            this.exittype = 'submit'
                            if (this.isEmpty) {
                                this.missVisible = true
                            } else {
                                if (this.$route.query.type == 0) {
                                    this.subtips = '您确定要提交吗?'
                                } else {
                                    this.subtips = '您确定要提交吗?一但提交,即完成本次比赛不可修改哦~'
                                }
                                this.dialogVisible = true;
                            }
                        })

                } else {
                    this.currentIndex += 1;
                    this.toggle()
                }
            },

            // 交卷
            submit() {
                return this.answer()
                    .then(res => {
                        let data = {
                            id: this.historyId
                        }
                        return this.$Api.Activity.subQuest(data)
                            .then(res => {
                                console.log('提交成功', res)
                                return Promise.resolve('提交成功')
                            })
                            .catch(err => {
                                console.log('提交失败', err)
                            })
                    })
            },
            // 显示上传区域
            showUpload() {
                this.UploadPicture = true
            },
            // 上传内容回调
            closeMain(val) {
                console.log('上传内容回调', val);
                if (this.picTypes.indexOf(val.filemd5.split("_")[2].toLowerCase()) != -1) {
                    // 上传的是图片
                    this.imglist.push(val.filemd5);
                    this.previewlist.push(this.imgurl + val.filemd5);
                } else if (this.videoTypes.indexOf(val.filemd5.split("_")[2].toLowerCase()) != -1) {
                    // 上传的是视频
                    this.videolist.push(val.filemd5);
                } else {
                    // 上传的是其他类型
                    let obj = {
                        name: val.name,
                        url: this.imgurl + val.filemd5
                    }
                    this.filelist.push(obj)
                }
                this.currentAnswer += val.filemd5 + ','
            },
            // 关闭右侧上传抽屉提示弹窗
            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => {});
            },
            closeDrawer() {
                console.log('关闭')
            },
            // 判断是否漏题
            missAnswer() {
                console.log('判断是否漏题')
                for (var i = 0; i < this.questionList.length; i++) {
                    if (!this.questionList[i].isAnswer) {
                        this.isEmpty = true
                        break
                    } else {
                        this.isEmpty = false
                    }
                }
            },

            // 测试代码
            test(text) {
                // console.log(text);
                // console.log('-----')
                var reg = /\[(.*?)\]/gi;
                var tmp = text.match(reg);
                // console.log('tmp', tmp)
                if (tmp) {
                    // let arr = []
                    // for (var i = 0; i < tmp.length; i++) {
                    //     let str = tmp[i]
                    //     arr.push(str)
                    //     // console.log(tmp[i]); // 保留中括号
                    // }
                    // let arr2 = tmp.filter(item => {
                    //     return item.split(":")[0] == '[IMG'
                    // })
                    // // console.log('imgArr', imgArr)
                    // arr2.forEach(item => {
                    //     text = text.replace(item,
                    //         `<img src="${this.imgurl}${item.split(":")[1].slice(0,item.split(":")[1].length-1)}">`
                    //     )
                    // })
                    tmp.forEach(item => {
                        let str = '';
                        if (item.split(":")[0] == '[IMG') {
                            str =
                                `<img src="${this.imgurl}${item.split(":")[1].slice(0,item.split(":")[1].length-1)}">`
                        }
                        if (item.split(":")[0] == '[AUDIO') {
                            str =
                                `<audio  controls controlsList="nodownload" src="${this.downloadURL}${item.split(":")[1].slice(0,item.split(":")[1].length-1)}" class="audio-intitle" "></audio>`
                        }

                        if (item.split(":")[0] == '[VIDEO') {
                            str =
                                `<video class="video-intitle" controls src="${this.downloadURL}${item.split(":")[1].slice(0,item.split(":")[1].length-1)}" controlsList="nodownload">您的浏览器不支持 video 标签。</video>`
                        }

                        // if (item.split(":")[0] == '[VOICE') {
                        //     console.log('当前题目',this.currentQuestion)
                        // }
                        if (item.split(":")[0] != '[VOICE') {
                            text = text.replace(item, str)
                        }

                    })
                    return text

                } else {
                    return text
                }
            },
            //停止录音
            stopRecorder() {
                this.recorder.stop();
                this.$message.success('录音成功');
                this.recorderLoading = false;
                let wavBlob = this.recorder.getWAVBlob();
                let file = new File([wavBlob], `audio-${new Date().getTime()}.wav`, {
                    type: 'audio/wav'
                })
                this.recorderURL = URL.createObjectURL(wavBlob);
                console.log(file)
                uploadOSSFile(file).then((res) => {
                    console.log('回调', res)
                    this.currentAnswer = res.identifier
                    this.answer();
                });
            },
        },
        mounted() {
            this.$route.meta.title = this.$route.query.type == 1 ? '正式比赛' : '模拟练习';
            this.getQuestion();

            // 处理题目格式
            // questArr.map(item => {
            //     item.title = this.test(item.title);
            //     item.answerOptions = JSON.parse(item.answerOptions);
            //     item.answerOptions.map(items => {
            //         items.label = this.test(items.label)
            //     })
            // })
            // console.log('questArr', questArr)
            // this.questionList = questArr
        },
        beforeDestroy() {
            clearInterval(this.intervalId);
            console.log('页面销毁，清除定时器')
        },
        watch: {
            questionList: {
                handler(newVal, oldVal) {
                    // console.log('题目列表改变', newVal)
                    var num = 0;
                    newVal.map(item => {
                        if (item.isAnswer) {
                            num += 1
                        }
                    })
                    // console.log('num--', num)
                    this.isAnswerNum = num
                },
                deep: true
            }
        },
        // 退出答题页面
        beforeRouteLeave(to, from, next) {
            console.log('退出答题页面')
            this.path = to.path;
            if (this.exit) {
                next()
            } else {
                this.missVisible = true
                this.exittype = 'exit'
            }
        }
    };
</script>
<style lang='less' scoped>
    .matchWrap {
        max-width: 1920px;
        min-width: 1200px;
        margin: 0 auto;
        cursor: default;

        /deep/ .el-breadcrumb {
            color: #333;
            margin: 49px 0 32px;
        }

        .match {
            margin-bottom: 130px;
            display: flex;

            .match_l {
                width: 356px;
                min-height: 633px;
                border-radius: 5px;
                background: #fff;
                padding: 17px 21px;
                box-sizing: border-box;
                box-shadow: 0px 2px 10px 0px #F5F8FA;

                .plan {
                    width: 100%;
                    height: 107px;
                    border-radius: 5px;
                    background: #F5F9FF;
                    color: #999;
                    padding: 20px;
                    box-sizing: border-box;
                    justify-content: space-between;

                    .plan_top {
                        font-size: 14px;

                    }
                }

                /deep/ .el-slider__runway {
                    margin: 0;
                    height: 2px;
                    background-color: #AFCBFF;
                }

                /deep/ .el-slider__button {
                    width: 10px;
                    height: 10px;
                }

                /deep/ .el-slider__runway.disabled .el-slider__button {
                    border-color: #409EFF;
                }

                /deep/ .el-slider__bar {
                    height: 3px;
                }

                /deep/ .el-slider__button-wrapper {
                    top: -16px;
                }

                /deep/ .el-slider__runway.disabled .el-slider__bar {
                    background: #409EFF;
                }

                .number_t {
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 32px;
                    padding: 0 20px;
                    justify-content: space-between;

                    .item {
                        width: 40px;
                        height: 40px;
                        border-radius: 5px;
                        text-align: center;
                        line-height: 40px;
                        color: #666;
                        font-size: 17px;
                        border: 1px solid #D8D8D8;
                        margin-bottom: 18px;
                        margin-right: 15px;
                    }

                    .item:nth-child(5n) {
                        margin-right: 0;
                    }

                    .hideitem {
                        .item();
                        height: 0;
                        border: 1px solid #fff;
                        margin-bottom: 0;

                    }

                    .hideitem:nth-child(5n) {
                        margin-right: 0;
                    }


                }

                .number_b {
                    .number_t();
                    margin-top: 0;
                }

                .select_item {
                    background: #F0F6FF;
                    color: #508EF9 !important
                }

                .isanswer {
                    background: #508EF9;
                    color: #fff !important
                }

                .line {
                    width: 100%;
                    height: 1px;
                    background-color: #E8E8E8;
                    margin-bottom: 18px;
                }
            }

            .match_r {
                box-shadow: 0px 2px 10px 0px #F5F8FA;
                margin-left: 18px;
                width: 826px;
                min-height: 633px;
                background: #fff;
                border-radius: 0 0 10px 10px;

                .title {
                    height: 74px;
                    padding: 0 21px;
                    background-image: url("../../assets/images/active/lianxi.png");
                    background-repeat: no-repeat;
                    background-size: 100% 100%;

                    span:first-child {
                        color: #fff;
                        font-size: 18px;
                    }

                    .time {
                        min-width: 196px;
                        height: 40px;
                        border-radius: 20px;
                        background: rgba(255, 255, 255, .2);
                        color: #fff;
                        padding: 0 20px;
                        box-sizing: border-box;
                        justify-content: space-around;

                        span:nth-of-type(1) {
                            font-size: 13px;
                            margin: 0 15px 0 8px;
                        }

                        span:nth-of-type(2) {
                            font-size: 20px;
                        }
                    }
                }

                .content {
                    padding: 0 21px;

                    .questype {
                        width: 82px;
                        height: 26px;
                        background-image: url('../../assets/images/active/type.png');
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        margin-top: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: space-around;

                        span {
                            color: #fff !important;
                            font-size: 18px;
                        }

                    }

                    span {
                        color: #333;
                        font-size: 18px;
                        margin: 29px 0;
                        display: inline-block;
                        font-weight: bold;
                    }

                    /deep/.qtitle {
                        color: #333;
                        font-size: 18px;
                        margin: 29px 0;
                        display: inline-block;
                        font-weight: bold;
                        white-space: pre-wrap;

                        img {
                            width: auto !important;
                            height: 100px !important;
                            vertical-align: middle
                        }
                    }

                    /deep/ .recorder {
                        border: 1px solid #333;
                        background-color: #508EF9;
                        color: #fff;
                        padding: 20px;
                    }



                    .line {
                        width: 100%;
                        height: 1px;
                        background-color: #F5F4F4;
                    }

                    .options {
                        .optionItem {
                            margin-top: 34px;
                            min-width: 410px;
                            min-height: 48px;
                            border: 1px solid #D8D8D8;
                            border-radius: 5px;
                            overflow: hidden;
                            display: flex;
                            align-items: stretch;

                            .serial {
                                width: 48px;
                                // height: inherit;
                                // height: 48px;
                                background-color: #F5F9FF;
                                text-align: center;
                                // line-height: 48px;
                                color: #333;
                                font-size: 16px;
                                display: flex;
                                align-items: center;
                                justify-content: space-around
                            }

                            .select_serial {
                                background: #508EF9;
                                color: #fff;
                                display: flex;
                                align-items: center;
                                justify-content: space-around
                            }

                            .optionContent {
                                // line-height: 48px;
                                padding-left: 20px;
                                background-color: #fff;
                                color: #333;
                                font-size: 16px;
                                display: flex;
                                align-items: center;
                                // justify-content: space-around;

                                /deep/ img {
                                    width: auto !important;
                                    height: 100px !important;
                                    vertical-align: middle
                                }
                            }

                            .select_optionContent {
                                background: #F5F9FF;
                                flex-grow: 1;
                                display: flex;
                                align-items: center;
                                // justify-content: space-around;

                                /deep/ img {
                                    width: auto !important;
                                    height: 100px !important;
                                    vertical-align: middle
                                }
                            }
                        }

                        .uploadWrap {
                            margin-top: 20px;

                            .imgwrap {
                                display: inline-block;
                                width: 200px;
                                height: 150px;
                                margin-right: 20px;
                                margin-bottom: 20px;
                                position: relative;
                                overflow: hidden;

                                .mask {
                                    position: absolute;
                                    right: 0;
                                    top: 0;
                                    width: 30px;
                                    height: 30px;
                                    background: rgba(0, 0, 0, 0.7);
                                    z-index: 9;
                                    visibility: hidden;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-around;
                                    border-radius: 0 0 0 5px;
                                }

                                &:hover .mask {
                                    visibility: visible;
                                }
                            }

                            .videowrap {
                                margin: 10px 20px 10px 0px;
                                width: 200px;
                                height: 150px;
                                position: relative;
                                overflow: hidden;

                                .mask {
                                    position: absolute;
                                    right: 0;
                                    top: 0;
                                    width: 30px;
                                    height: 30px;
                                    background: rgba(0, 0, 0, 0.7);
                                    z-index: 9;
                                    visibility: hidden;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-around;
                                    border-radius: 0 0 0 5px;
                                }

                                &:hover .mask {
                                    visibility: visible;
                                }
                            }
                        }

                        /deep/ .el-button--primary {
                            width: 100px;
                            margin: 50px auto 0;
                        }

                        /deep/ .el-image {
                            margin-right: 15px;
                            border-radius: 3px;
                        }
                    }
                }

                .btn {
                    margin: 50px auto;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;

                    .button {
                        width: 150px;
                        height: 38px;
                        border-radius: 5px;
                        text-align: center;
                        line-height: 38px;
                        font-size: 18px;
                        display: inline-block;
                    }

                    .prev {
                        .button();
                        border: 1px solid #666;
                        color: #666;
                        margin-right: 64px;
                    }

                    .noprev {
                        .button();
                        border: 1px solid #D8D8D8;
                        color: #D8D8D8;
                        margin-right: 64px;
                    }

                    .next {
                        .button();
                        background-color: #508EF9;
                        color: #fff;
                    }
                }
            }

            /deep/ .el-dialog {
                width: 532px;
                height: 312px;
                border-radius: 10px;
                background-image: url('../../assets/images/active/popbg.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;
                position: relative;

                .subtip {
                    width: 124px;
                    height: 85px;
                    position: absolute;
                    top: -14px;
                    left: 50%;
                    transform: translateX(-50%);
                }

                .tip {
                    color: #333;
                    font-size: 18px;
                    line-height: 128px;
                    margin-top: 25px;
                }

                .btn {
                    .error {
                        width: 149px;
                        height: 38px;
                        border-radius: 5px;
                        line-height: 38px;
                        text-align: center;
                        color: #666;
                        font-size: 16px;
                        border: 1px solid #508EF9;
                        box-sizing: border-box
                    }

                    .ready {
                        .error();
                        color: #fff;
                        margin-left: 64px;
                        background: #508EF9;
                        border: none
                    }
                }

                .close {
                    width: 28px;
                    height: 28px;
                    position: absolute;
                    top: 14px;
                    right: 15px;
                }

                .medal {
                    width: 157px;
                    height: 217px;
                    position: absolute;
                    top: -40px;
                }

                .tips {
                    font-size: 18px;
                    color: #333;
                    margin-top: 156px
                }
            }

            /deep/ .el-dialog__body {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: none;

                .btn {
                    display: flex;
                }
            }

            .finishpop {
                /deep/ .el-dialog {
                    width: 532px;
                    height: 356px;
                    border-radius: 10px;
                    background-image: url('../../assets/images/active/popbg2.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    position: relative;

                    .subtip {
                        width: 124px;
                        height: 85px;
                        position: absolute;
                        top: -14px;
                        left: 50%;
                        transform: translateX(-50%);
                    }

                    .tip {
                        color: #333;
                        font-size: 18px;
                        line-height: 128px;
                        margin-top: 25px;
                    }

                    .btn {
                        .error {
                            width: 149px;
                            height: 38px;
                            border-radius: 5px;
                            line-height: 38px;
                            text-align: center;
                            color: #666;
                            font-size: 16px;
                            border: 1px solid #508EF9;
                            box-sizing: border-box
                        }

                        .ready {
                            .error();
                            color: #fff;
                            margin-left: 64px;
                            background: #508EF9;
                            border: none
                        }
                    }

                    .close {
                        width: 28px;
                        height: 28px;
                        position: absolute;
                        top: 14px;
                        right: 15px;
                    }

                    .medal {
                        width: 157px;
                        height: 217px;
                        position: absolute;
                        top: -40px;
                    }

                    .tips {
                        font-size: 18px;
                        color: #333;
                        margin-top: 156px
                    }
                }
            }

            .timepop {
                /deep/ .el-dialog {
                    width: 534px;
                    height: 312px;
                    border-radius: 10px;
                    background-image: url('../../assets/images/active/notjoinbg.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    position: relative;

                    .timeend {
                        width: 191px;
                        height: 121px;
                        position: absolute;
                        top: -14px;
                    }

                    .endConfirm {
                        width: 149px;
                        height: 38px;
                        border-radius: 5px;
                        line-height: 38px;
                        text-align: center;
                        color: #666;
                        font-size: 16px;
                        box-sizing: border-box;
                        color: #fff;
                        background: #508EF9;
                        border: none
                    }
                }
            }

            .misspop {
                /deep/ .el-dialog {
                    width: 534px;
                    height: 312px;
                    border-radius: 10px;
                    background-image: url('../../assets/images/active/notjoinbg.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    position: relative;

                    .bell {
                        position: absolute;
                        width: 105px;
                        height: 105px;
                        top: -16px
                    }

                    .btn {
                        .error {
                            width: 149px;
                            height: 38px;
                            border-radius: 5px;
                            line-height: 38px;
                            text-align: center;
                            color: #666;
                            font-size: 16px;
                            border: 1px solid #508EF9;
                            box-sizing: border-box
                        }

                        .ready {
                            .error();
                            color: #fff;
                            margin-left: 64px;
                            background: #508EF9;
                            border: none
                        }
                    }
                }
            }
        }
    }
</style>